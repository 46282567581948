<!-- 叫车 -->
<template>
  <div class="page">
    <div id="headerBox" ref="headerRef" class="header">
      <div
        :style="{ height: `${appBarHeight}px`, backgroundColor: 'white' }"
      ></div>
      <div class="navBar">
        <img
          @click="handleBack"
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
          alt=""
        />
        <div class="title">预约叫车</div>
        <!-- <span class="record" @click="recordClick">记录</span> -->
      </div>
    </div>
    <div
      class="header-placehold"
      :style="{
        height: `${headerH + appBarHeight}px`,
        backgroundColor: 'white',
      }"
    ></div>
    <div class="content" ref="contentBox">
      <div class="plate">
        <div class="content-title"><span>*</span>装货点</div>
        <div class="select-loading-point" @click="selectLoadingPointClick">
          <span
            :class="{
              disabled: loadingPointList.length === 0 || !selectPoint.value,
            }"
            >{{ selectPoint.text }}</span
          >
          <img
            v-show="loadingPointList.length !== 0"
            src="../../assets/imgs/showAll.png"
          />
        </div>
      </div>
      <div class="plate">
        <div class="content-title"><span>*</span>班次</div>
        <div class="select-loading-point" @click="selectClassesClick">
          <span
            :class="{
              disabled: !selectClasses.value,
            }"
            >{{ selectClasses.text }}</span
          >
          <img src="../../assets/imgs/showAll.png" />
        </div>
      </div>
      <div class="plate">
        <div class="content-title"><span>*</span>需求车辆到达时间</div>
        <div class="select-loading-point" @click="isShowTimerPicker = true">
          <span
            :class="{
              disabled: !arriveTime,
            }"
            >{{ arriveTime ? arriveTime : '请选择车辆到达时间' }}</span
          >
          <img src="../../assets/imgs/showAll.png" />
        </div>
      </div>
      <div class="plate car-num">
        <div class="use-num">
          <div class="content-title"><span>*</span>用车数</div>
          <van-stepper
            v-model="useVehicleNum"
            :min="1"
            :max="4"
            :integer="true"
            :disable-input="true"
          />
        </div>
        <div class="cage-num">
          <div class="content-title"><span>*</span>笼数</div>
          <van-stepper
            v-model="cageNum"
            :min="0"
            :max="maxCageNum"
            :integer="true"
            :disable-input="true"
          />
        </div>
      </div>
      <div class="plate">
        <div class="content-title">备注</div>
        <div class="remark">
          <van-field
            ref="remark"
            v-model="remark"
            rows="2"
            autosize
            type="textarea"
            maxlength="100"
            placeholder="请输入备注，例如：需要额外的袋子"
            show-word-limit
            @focus="handleInputFocus"
            @blur="handleInputBlur"
          />
        </div>
      </div>
      <!-- <div class="plate">
        <div class="content-title">卸货点</div>
        <div class="unload-point">
          {{ loadingPoint.name }}
        </div>
      </div> -->
    </div>
    <div class="bottom" v-show="hideshow">
      <div
        class="bottom-btn"
        :class="{ disabled: !isReservationAvailable }"
        @click="onConfirmCarHailling"
      >
        确定预约无人车
      </div>
    </div>

    <van-popup
      class="loading-point"
      get-container="body"
      v-model="isShowPointPicker"
      position="bottom"
      @close="onCancel"
      style="border-radius: 16px 16px 0px 0px"
    >
      <van-picker
        title="选择装货点"
        confirm-button-text="确定"
        show-toolbar
        :columns="loadingPointList"
        :default-index="defaultIndex"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup
      class="loading-point"
      get-container="body"
      v-model="isShowClassesPicker"
      position="bottom"
      @close="onCancelClasses"
      style="border-radius: 16px 16px 0px 0px"
    >
      <van-picker
        title="班次选择"
        confirm-button-text="确定"
        show-toolbar
        :columns="classesList"
        @confirm="onConfirmClasses"
        @cancel="onCancelClasses"
      />
    </van-popup>
    <van-popup
      class="loading-point"
      get-container="body"
      v-model="isShowTimerPicker"
      position="bottom"
      @close="onCancelTimer"
      style="border-radius: 16px 16px 0px 0px"
    >
      <van-datetime-picker
        v-model="arriveTime"
        type="time"
        title="最晚到达时间"
        confirm-button-text="确定"
        :min-hour="minHour"
        :formatter="formatter"
        @confirm="onConfirmTimer"
        @cancel="onCancelTimer"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
import NDialog from '@/components/NDialog'; // 支持promise的dialog
import { carHailingSuccessFlag } from '@/utils/index';
import { getCallVehicleStation, callVehicle } from '@/api/apiv2';
import { param2Obj } from '@/utils';

let u = navigator.userAgent;
let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
export default {
  components: {},
  data() {
    return {
      appBarHeight: parseInt(sessionStorage.getItem('_appBarHeight') ?? 0),
      headerH: 48,
      isShowPointPicker: false, // 是否展示卸货点选择组件
      loadingPointList: [], // 装货点列表
      selectPoint: { text: '请选择装货点' }, //选择的卸货点
      isShowClassesPicker: false, // 是否展示班次选择组件
      defaultIndex: null,
      loadingPoint: {}, // 选择的装货点
      classesList: [
        { text: '01P', value: '01P' },
        { text: '02P', value: '02P' },
        { text: '03P', value: '03P' },
        { text: '04P', value: '04P' },
        { text: '05P', value: '05P' },
        { text: 'T网', value: 'T网' },
      ],
      selectClasses: { text: '请选择班次' }, //选择的班次
      isShowTimerPicker: false,
      minHour: 0,
      arriveTime: null,
      useVehicleNum: 1, // 用车数
      cageNum: 0, // 笼车数
      maxCageNum: 3, // 最大笼车数
      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
      hideshow: true, // 是否隐藏底部按钮
      isLoading: false,
      remark: '',
      versionFlag: 0, // 兼容原生新旧包返回不同的方式
    };
  },
  computed: {
    isReservationAvailable() {
      return (
        this.selectPoint.value &&
        this.selectClasses.value &&
        this.arriveTime &&
        !this.isLoading
      );
    },
  },
  watch: {
    showHeight() {
      // 奇葩问题
      if (this.docmHeight > this.showHeight + 3) {
        this.hideshow = false;
      } else {
        this.hideshow = true;
        if (!isIOS) {
          this.$refs.remark.blur();
        }
      }
    },
    useVehicleNum: {
      handler(newV, oldV) {
        this.maxCageNum = this.useVehicleNum * 3;
        if (newV <= oldV) {
          this.cageNum = 0;
        }
      },
    },
  },
  created() {},
  mounted() {
    const now = new Date();
    const hours = now.getHours();
    this.minHour = hours;
    this.getCallVehicleStationServer();
    window.addEventListener('resize', () => {
      this.showHeight = document.body.clientHeight;
    });
    const query = param2Obj(location.href);
    this.versionFlag = parseInt(query.versionFlag);
  },
  methods: {
    handleBack() {
      window.location.href = 'neolix://more';
    },
    recordClick() {
      this.$router.push('/CarHailingRecord');
    },
    // 装货点
    selectLoadingPointClick() {
      if (this.loadingPointList.length === 0) return;
      this.isShowPointPicker = true;
    },
    onConfirm(value, index) {
      this.selectPoint = value;
      this.onCancel();
    },
    onConfirmLoadingPoint() {
      this.onCancel();
    },
    onCancel() {
      this.isShowPointPicker = false;
    },
    // 班次
    selectClassesClick() {
      this.isShowClassesPicker = true;
    },
    onConfirmClasses(value) {
      this.selectClasses = value;
      this.onCancelClasses();
    },
    onCancelClasses() {
      this.isShowClassesPicker = false;
    },
    // 时间
    onConfirmTimer(value) {
      this.arriveTime = value;
      this.onCancelTimer();
    },
    onCancelTimer() {
      this.isShowTimerPicker = false;
    },
    handleInputBlur() {
      this.$refs.contentBox.scrollTop = 0;
    },
    handleInputFocus(event) {
      // 区分iOS 和 安卓
      if (isIOS) {
        // iOS主要使用scrollTop，且需要加上延迟
        // document.getElementByClassName iOS容易获取不到dom
        setTimeout(() => {
          this.$refs.contentBox.scrollTop = 200;
          this.hideshow = false;
        }, 400);
      } else {
        this.hideshow = false;
        // scroll 在iOS的兼容性不好
        setTimeout(() => {
          this.$refs.contentBox.scroll({
            top: 200,
            left: 0,
          });
        }, 300);
      }
    },
    // 提交预约
    async onConfirmCarHailling() {
      if (!this.isReservationAvailable || this.isLoading) return;

      const result = await NDialog.confirm({
        title: '确定呼叫无人车',
        message: '确定后，系统将自动分配车辆，请留意车号',
      });
      if (result === 'confirm') {
        this.isLoading = true;
        this.$loadingCircle.start();
        callVehicle({
          loadingStationId: this.selectPoint.value,
          unloadStationId: this.loadingPoint.id,
          workShift: this.selectClasses.value,
          useVehicleNum: this.useVehicleNum,
          cageNum: this.cageNum,
          arriveTime: `${this.arriveTime}:00`,
          remark: this.remark,
        })
          .then((res) => {
            this.reservationSuccessful();
          })
          .finally(() => {
            this.isLoading = false;
            this.$loadingCircle.end();
          });
      }
    },
    // 预约成功
    async reservationSuccessful() {
      const result = await NDialog.confirm({
        title: '已提交预约',
        message: '系统分配车辆中，预约结果将发送短信通知',
        okText: '我知道了',
        isHideCancel: true,
      });
      if (result === 'confirm') {
        if (this.versionFlag === 1) {
          carHailingSuccessFlag();
        } else {
          this.handleBack();
        }
      }
    },
    getCallVehicleStationServer() {
      getCallVehicleStation().then((res) => {
        this.loadingPointList = res.loadingStation.map((station) => {
          return { text: station.name, value: station.id };
        });
        if (this.loadingPointList.length === 0) {
          this.selectPoint = { text: '暂无装货点' };
        }
        if (res.unloadStation?.length > 0) {
          this.loadingPoint = res.unloadStation[0];
        }
        if (res.historyRecord?.id) {
          this.selectPoint = {
            text: res.historyRecord.name,
            value: res.historyRecord.id,
          };
          this.defaultIndex =
            this.loadingPointList.findIndex(
              (item) => item.value === res.historyRecord.id
            ) || 0;
        }
      });
    },
    formatter(type, val) {
      if (type === 'hour') {
        return val + '时';
      }
      if (type === 'minute') {
        return val + '分';
      }
      return val;
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  position: relative;
  background: white;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
  display: flex;
  flex-direction: column;
  .header {
    width: 100vw;
    position: fixed;
    z-index: 1;
    background: #f6f7f8;
    top: 0;
    .navBar {
      height: 48px;
      line-height: 48px;
      position: relative;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      font-weight: bold;

      img {
        width: 0.64rem;
        height: 0.64rem;
        position: absolute;
        top: 0.07rem;
        left: 0.2rem;
        z-index: 3;
      }
      .title {
        background: white;
        font-size: 15px;
        font-weight: 600;
      }
      .record {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 60px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        color: #436eff;
      }
    }
  }
  .header-placehold {
    width: 100%;
    height: 48px;
    background: rgba(245, 246, 250, 1);
  }
  .content {
    position: relative;
    padding-bottom: 100px;
    overflow: scroll;
    // height: 100vh;
    flex: 1;
    .plate {
      padding: 0 16px;
      box-sizing: border-box;
    }
    .car-num {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 12px 0 20px;
      .use-num {
        padding-right: 10px;
        box-sizing: border-box;
      }
      .cage-num {
        padding-left: 10px;
        box-sizing: border-box;
      }
    }
    .content-title {
      span {
        display: inline-block;
        width: 8px;
        font-size: 15px;
        font-weight: 600;
        color: rgba(255, 57, 27, 1);
      }
      font-size: 16px;
      font-weight: 600;
    }
    .select-loading-point {
      position: relative;
      width: 100%;
      height: 48px;
      border-radius: 6px;
      background: #f4f7f9;
      border: 1px solid #eaeeee;
      margin: 12px 16px 20px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      padding-right: 30px;
      margin: 12px 0 20px;

      span {
        font-size: 16px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.disabled {
          color: rgba(151, 152, 171, 1);
        }
      }
      img {
        position: absolute;
        width: 12px;
        height: 12px;
        right: 10px;
      }
    }
    .unload-point {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
    }
    .remark {
      border-radius: 6px;
      background: rgba(244, 247, 249, 1);
      border: 1px solid rgba(234, 238, 238, 1);
      margin-top: 12px;
      margin-bottom: 20px;
      ::v-deep .van-field {
        background: transparent;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 86px;
    background: white;
    padding-top: 16px;
    .bottom-btn {
      height: 46px;
      margin: 0 24px;
      background: #436eff;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 46px;
      font-size: 14px;
      font-weight: 600;
      &.disabled {
        background: #cbd5fb;
      }
    }
  }

  .loading-point {
    ::v-deep .van-picker__toolbar {
      height: 55px;
      .van-picker__cancel {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        font-weight: 400;
      }
      .van-picker__confirm {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #436eff;
        letter-spacing: 0;
        font-weight: 400;
      }
      .van-picker-column__item {
        padding: 0 10px;
      }
    }
  }
}
::v-deep .van-picker__toolbar {
  height: 55px;
  .van-picker__cancel {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }
  .van-picker__title {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
  }
  .van-picker__confirm {
    font-size: 14px;
    font-weight: 400;
    color: rgba(67, 110, 255, 1);
  }
}
::v-deep .van-picker-column {
  .van-picker-column__item--selected {
    color: #436eff;
  }
}
::v-deep .van-stepper {
  margin-top: 12px;
  width: 100%;
  height: 48px;
  border-radius: 6px;
  background: rgba(244, 247, 249, 1);
  border: 1px solid rgba(234, 238, 238, 1);
  display: grid;
  grid-template-columns: 48px 1fr 48px;
  align-items: center;
  justify-content: center;
  button {
    width: 48px;
    height: 48px;
    background-color: transparent;
    color: #000;
    &::before {
      width: 30%;
      height: 2px;
    }
    &::after {
      height: 30%;
      width: 2px;
    }
    &.van-stepper__minus--disabled,
    &.van-stepper__plus--disabled {
      color: rgba(204, 204, 204, 1) !important;
    }
  }
  input {
    width: 100%;
    font-size: 18px;
    color: black;
    background-color: transparent;
    height: 40px;
  }
}
</style>
