import axios from 'axios';
import qs from 'qs';
import { getToken } from './auth';
import { neterrorPage } from './index';
import env from '../../env';
import { Toast } from 'vant';

const { baseURL2: baseURL } = env; // 都是sc的
const request = axios.create({
  baseURL,
  timeout: 15000,
});
request.interceptors.request.use(
  (config) => {
    if (config.headers['Content-Type'] == 'application/x-www-form-urlencoded') {
      config.data = qs.stringify(config.data);
    } else if (config.data instanceof FormData) {
      config.headers['Content-Type'] == 'multipart/form-data'; // 这里应该是有bug
    } else {
      config.headers['Content-Type'] == 'application/json';
    }
    if (!config.withoutToken) config.headers['Authorization'] = getToken();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    if (response.config.customHandle) {
      return response.data;
    } else {
      const { data, code, msg } = response.data;
      switch (code) {
        case '10000':
          return data;
        case '10033':
          window.location.href = 'neolixjs://webview?url=login';
          return;
        default:
          Toast({
            message: msg,
            className: 'response-toast',
            forbidClick: true,
          });
          return Promise.reject({ code, msg });
      }
    }
  },
  (error) => {
    neterrorPage();
    return Promise.reject(error);
  }
);

export default request;
